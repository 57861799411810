<template>

    <ws-dialog
        v-model="display"
        :title="$t('avalon.element.edit')"
        style="z-index: 500"
        overlay-color="transparent"
        width="320"
        fullscreen-align="right"
        not-centered
        no-navigation
        margin-right="25px"
        margin-top="68px"
        margin-bottom="25px"
        border-radius="8"
        blank
    >

      <template #header>
        <h4 class="py-4 px-6">{{ $t('Edit') }}</h4>
      </template>

      <template #default>
        <div style="height: 500px">
          <certificate-image-settings
              @input="editElement"
              v-if="element.type === 'image'"
              v-model="element"
          />

          <certificate-text-settings
              @input="editElement"
              v-if="element.type === 'text'"
              v-model="element"
          />

          <certificate-rectangle-settings
              @input="editElement"
              v-if="element.type === 'rectangle'"
              v-model="element"
          />
          <certificate-line-settings
              @input="editElement"
              v-if="element.type === 'line'"
              v-model="element"
          />
        </div>
      </template>

  </ws-dialog>

</template>

<script>
import {mapActions, mapState} from "vuex";
import certificateImageSettings
  from "@/components/pages/westudy/admin/course/courseCertificates/editor/dialogs/certificateImageSettings";
import certificateTextSettings
  from "@/components/pages/westudy/admin/course/courseCertificates/editor/dialogs/certificateTextSettings";
import certificateRectangleSettings
  from "@/components/pages/westudy/admin/course/courseCertificates/editor/dialogs/certificateRectangleSettings";
import certificateLineSettings
  from "@/components/pages/westudy/admin/course/courseCertificates/editor/dialogs/certificateLineSettings";

export default {
  name: "avalonBlocSettings",
  components : {
    certificateImageSettings,
    certificateTextSettings,
    certificateRectangleSettings,
    certificateLineSettings
  },
  props : {
    value : {
      type : Boolean,
      default : false,
    },
    element : {
      type : Object,
      default() { return {} }
    },
  },
  data() {
    return {
      display : false,
      preventClose : false
    }
  },
  computed : {
    ...mapState('avalon',['course' , 'pagesSelect' , 'coursesSelect']),
    ...mapState('avalonPage',['course']),


    buttonActions() {
      return [
        { text : this.$t('OpenPage') , value : 'page' },
        { text : this.$t('OpenLink') , value : 'link' },
        { text : this.$t('EnrollOnCourse') , value : 'enroll_on_course' },
        // { text : this.$t('OpenLogin') , value :  'auth' },
        { text : this.$t('GotoDashboard') , value :  'dashboard' },
      ]
    },
    blockSections() {
      let items = [
        { name : this.$t('Style') , value : 'background' , icon : 'mdi-palette', expanded : true },
        //   { name : this.$t('Paddings') , value : 'format', icon : 'mdi-arrow-all' , expanded : false  },
      ]
      if (this.element.type === 'line' ) {
        items.push(  { name : this.$t('Line') , value : 'line', icon : 'mdi-button-cursor' , expanded : true  } )
      }


      return items

    },
    backgroundTypes() {
      return [
        { text : this.$t('Color') , value : 'color' , icon : 'mdi-palette'},
        // { text : this.$t('Image') , value : 'image' , icon : 'mdi-image'},
        // { text : this.$t('Video_') , value : 'video' , icon : 'mdi-video'},
        { text : this.$t('None')  ,  value : null   , icon : 'mdi-cancel'}
      ]
    }
  },
  watch : {
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    },
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input',this.display)
      }
    }
  },
  methods : {
    ...mapActions('avalonPage',['EDIT_ELEMENT']),

    async editElement(element = null) {

      await this.EDIT_ELEMENT(element || this.element)
      this.$emit('update' , this.COPY(element || this.element) )
    },

    formLeadChange(value , field) {
      if ( !value ) {
        this.element.config.fields = this.element.config.fields.filter( el => el !== field)
      } else {
        if (  !this.element.config.fields.includes(field) ) {
          this.element.config.fields.push(field)
        }
      }

      this.element = Object.assign({} , this.element)
      this.editElement()
    },

    // line

  },
  mounted() {
    this.display = this.value
    if (!this.element.config) {
      this.element.config = {}
    }
    if (!this.element.config.course && this.course.uuid ) {
      this.element.config.course = this.course.uuid
    }
  }
}
</script>

<style scoped>
.block-style {
  position:fixed;
  outline: none;
  top:0;bottom: 0;
  right: 0;
  height: 100% ;
  z-index:1111
}
</style>