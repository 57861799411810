<template>
  <div>

    <a-parameter-switch
        @input="editElement"
        v-model="element.config.vertical"
        label="avalon.line.vertical"
        class="mt-6"
        light
    />
    <v-divider class="mt-3 mb-3" :style="`border-color : ${wsDARKLIGHT}; `" />

    <a-parameter-slider
        v-model="element.config.thickness"
        @change="editElement"
        :track-color="wsDARKLIGHT"
        :value-color="wsACCENT"
        label="Thickness"
        min="1"
        max="14"
        light
    />


    <color-param-picker
        @input="editElement"
        v-model="element.config.background_color"
        label="Color"
        :label-color="wsDARKER"
        class="pt-4"
        divider
        font-regular
    />


  </div>


</template>

<script>
import colorParamPicker from "@/components/AvalonEditor/UI/colorParamPicker";
export default {
  name: "certificateImageSettings",
  components : {
    colorParamPicker
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      element : {}
    }
  },
  computed : {
    dynamicTextTypeSelect() {
      return [
        { text : this.$t('SchoolName') , value : 'business_name' },
        { text : this.$t('StudentName') , value : 'student_name' },
        { text : this.$t('Date') , value : 'date' },
        { text : this.$t('CourseName') , value : 'course_name' },
        { text : this.$t('Result') , value : 'result' },
        { text : this.$t('CertificateNumber') , value : 'certificate_number' },
      ]
    }
  },
  watch : {
    value() {
      if ( !this.EQ(this.value , this.element ) ) {
        this.element = this.COPY(this.value)
      }
    }
  },
  methods : {
    editLineValign(value) {
      this.element.config.valign = value
      this.editElement()
    },
    editElement() {
      this.$emit('input' , this.COPY(this.element) )
    }
  },
  mounted() {
    this.element = this.value || {}
  }
}
</script>

<style scoped>

</style>