<template>

  <v-dialog
      :value="display"
      fullscreen
      persistent
  >
    <portal-target name="element_settings" />
    <v-sheet class="fill-height">
      <v-row class="no-gutters flex-wrap flex-column fill-height pb-6" >


        <!-- HEADER -->
        <v-col cols="auto" class="shrink" >
          <v-sheet class="d-flex justify-space-between align-center pl-5 py-2" :color="wsLIGHTCARD">
            <h5>
              <v-icon class="mr-1"   :color="wsACCENT">mdi-certificate</v-icon>
              {{ $t('certificates.edit') }} : {{ $store.state.courses.course.name }}
            </h5>

            <v-btn @click="displaySettings = true" :color="wsACCENT" text class="noCaps">
              {{ $t('Settings') }}
              <v-icon class="ml-2"  :color="wsACCENT">mdi-cog</v-icon>
            </v-btn>
          </v-sheet>
        </v-col>
        <!-- CONTENT -->

        <v-col cols="auto" class="grow  " style="height: 10px"  >
          <v-sheet color="white" height="100%" min-height="566"  class="d-flex justify-center align-center overflow-auto ">
            <v-sheet width="800" min-height="566" height="566" color="transparent" class="wsRoundedHalf">

                <certificate-settings
                    @close="displaySettings = false "
                    v-if="displaySettings"
                    v-model="displaySettings"
                    :block="block"
                    @update="updateBlock($event)"
                    @updateImage="updateImage"
                    :maxHeight="maxHeight"
                    :maxHeightSM="maxHeightSM"
                    @editing="editing = $event"
                />

                <!-- Add Element  -->
              <v-sheet  class="d-flex justify-end pb-5">
                <div style="z-index: 250">
                  <ft-select
                      :items="itemTypesSelect"
                      @input="addElement($event)"
                      :icon-color="wsATTENTION"
                      @expand="edit = $event"
                  >
                    <v-btn :color="wsACCENT" height="40" :style="`border : 1px solid ${wsBACKGROUND}`" text  class="noCaps" elevation="0">
                      <v-icon >mdi-plus</v-icon>
                      {{ $t('AddElement') }}
                    </v-btn>
                  </ft-select>
                </div>
              </v-sheet>

                <!-- Block Edit  -->


              <v-hover v-slot="{hover}">
                <div style="position:relative; padding-bottom: 150px" >

                  <!-- :: BLOCK CONTENT -->
                  <div :style="layoutStyle(hover)"
                       class="d-flex justify-center wsRoundedLight"
                       ref="blockBackground"
                       style="position : relative ">

                    <v-sheet
                        :color="GET_AVALON_COLOR(avalonBlockConfig(block, 'overlay_color' ))"
                        :style="`opacity : ${avalonBlockConfig(block, 'overlay_opacity' )/100}`"
                        class="fill-height"
                        width="100%"
                        style="position:absolute;"
                    />

                    <v-sheet
                        :width="blockWidth"
                        :style="style"
                        color="transparent"
                    >

                      <certificate-canvas
                          :key="trigger"
                          @moving="moving = $event"
                          :block="block"
                          v-model="blockElements"
                      >
                      </certificate-canvas>

                    </v-sheet>
                  </div>

                </div>
              </v-hover>
            </v-sheet>
          </v-sheet>
        </v-col>


        <!-- FOOTER -->
        <v-col cols="auto" class="shrink" >

          <v-sheet style="position: fixed; bottom : 0; left: 0;right: 0"
                   class="d-flex justify-space-between px-4 py-4 " >

            <v-btn @click="displayDelete = true" :color="wsWARNING" large  dark class="noCaps mr-3">
              {{ $t('Delete') }}
            </v-btn>

            <div class="d-flex align-center">
              <v-btn @click="display = false" :color="wsACCENT" large  outlined class="noCaps mr-3">
                {{ $t('Back') }}
              </v-btn>
              <v-btn @click="saveCertificate" large :color="wsATTENTION" dark class="noCaps">
                {{ $t('Save') }}
              </v-btn>
            </div>

          </v-sheet>

        </v-col>

      </v-row>

      <ws-dialog
          v-model="displayDelete"
          @delete="deleteCertificate"
          :title="$t('certificates.design.delete.title')"
          display-confirm-delete
          close-delete
          show-delete
      />


    </v-sheet>


  </v-dialog>



</template>

<script>
import {mapActions, mapState} from "vuex";
import certificateSettings from "@/components/pages/westudy/admin/course/courseCertificates/editor/certificateSettings";
import certificateCanvas from "@/components/pages/westudy/admin/course/courseCertificates/editor/CertificateCanvas";
export default {
  name: "CertificatesEditor",
  components : {
    certificateSettings,
    certificateCanvas,
  },
  props : {
    value : {
      type : Boolean,
      default : false
    },
    uuid : {
      type : String
    },
    triggerUpdate : {
      type : Number,
      default : 0
    },
    overlap : {
      type : Boolean,
      default : false
    },
    footer : {
      type : Boolean,
      default : false
    },
    template : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      block : {},
      elements : [],
      trigger : 0,
      moving : false,
      displayDelete : false,
      display : false,
      edit : false,
      editing : false,
      displaySettings : false,
      blockElements : [],
      cellDimensions : {},
      newElementTrigger : 0,
      maxHeight : 0,
      maxHeightSM : 0,
      triggerSyncResize : 0,

    }
  },
  computed : {
    ...mapState('avalon',[ 'editorMode' , 'header' , 'scrollPosition'  ]),
    ...mapState('courses', [
      'selectedLang' ,
    ]),

    zIndex() {
      if ( this.blockElements.length === 0 ) {
      return 0
      }
      let element = this.blockElements.reduce((prev, current) => (prev.z > current.z) ? prev : current)
      return  element ? element.z : 0
    },

    itemTypesSelect() {
      return [
        { text : this.$t('Text')        , value : 'text'    , icon : 'mdi-format-size'   },
        { text : this.$t('Image')       , value : 'image'   , icon : 'mdi-image'         },
        { text : this.$t('Rectangle')   , value : 'rectangle'  , icon : 'mdi-rectangle'  },
        { text : this.$t('Line')        , value : 'line'  , icon : 'mdi-minus'           },
        { text : this.$t('QRCode')      , value : 'qrcode'  , icon : 'mdi-qrcode'        },
      ]
    },


    style() {
      let style = ""

      let overlap = this.overlap && this.header.config.overlap ? this.header.config.height : 0
      if ( this.MOBILE_VIEW ) {
        overlap = this.overlap && this.header.config.overlap ? this.header.config.height_sm : 0
      }

      if ( this.avalonBlockConfig(this.block, 'full_height') ) {
        style += `height : 100vh;`
      }

      if ( !this.MOBILE_VIEW) {

        if ( this.avalonBlockConfig(this.block, 'vertical_padding')   && this.block.config ) {
          if ( this.block.config.padding_top || this.block.config.padding_top === 0 ) {
            style += `padding-top : ${(this.block.config.padding_top !== undefined ? this.block.config.padding_top : this.avalonConfig.block.padding_top) + overlap }px;`
          }
          if ( this.block.config.padding_bottom || this.block.config.padding_bottom === 0 ) {
            style += `padding-bottom : ${this.block.config.padding_bottom !== undefined ? this.block.config.padding_bottom : this.avalonConfig.block.padding_bottom}px;`
          }
        } else if ( this.overlap && this.header.config.overlap ) {
          style +=  `padding-top : ${this.avalonBlockConfig(this.block, 'padding_top') + overlap }px;`
        }

      }

      if ( this.MOBILE_VIEW) {

        if ( this.avalonBlockConfig(this.block, 'vertical_padding_sm')   && this.block.config ) {
          if ( this.block.config.padding_top_sm || this.block.config.padding_top_sm === 0 ) {
            style += `padding-top : ${(this.block.config.padding_top_sm !== undefined ? this.block.config.padding_top_sm : this.avalonConfig.block.padding_top_sm) + overlap }px;`
          }
          if ( this.block.config.padding_bottom_sm || this.block.config.padding_bottom_sm === 0 ) {
            style += `padding-bottom : ${this.block.config.padding_bottom_sm !== undefined ? this.block.config.padding_bottom_sm : this.avalonConfig.block.padding_bottom_sm}px;`
          }
        } else if ( this.overlap && this.header.config.overlap ) {
          style +=  `padding-top : ${this.avalonBlockConfig(this.block, 'padding_top_sm') + overlap }px;`
        }

      }



      return style
    },
    blockWidth() {
      if ( this.avalonBlockConfig(this.block, 'full_width')) {
        return '100%'
      }
      if ( this.avalonBlockConfig(this.block, 'width') ) {
        return this.avalonBlockConfig(this.block, 'width').toString()
      }
      return '100%'
    },
    defaultElements() {
      return {
        text: {
          type : 'text',
          x1: 25,
          x2: 250,
          y1: 25,
          y2: 75,
          config : {
            has_background : false,
            valign : 'center',
            font_color : '#000000'
          },
          z: 0,
          active: false,
          text : "Введіть ваш текст"
        },
        image: {
          type : 'image',
          x1: 25,
          x2: 275,
          y1: 25,
          y2: 225,
          z: 0,
          active: false,
          config :  {
            contain : false ,
            rounded : 4
          },
        },
        rectangle: {
          type : 'rectangle',
          x1: 25,
          x2: 275,
          y1: 25,
          y2: 175,
          z: 0,
          active: false,
          config :  {
            background_color : '#FE834B',
            rounded : 4 ,
          },
        },
        line: {
          type : 'line',
          x1: 25,
          x2: 175,
          y1: 25,
          y2: 50,
          z: 0,
          active: false,
          config :  {
            color : '#000000',
            rounded : 4 ,
            valign : 'center'
          },
        },
        qrcode: {
          type : 'qrcode',
          x1: 25,
          x2: 125,
          y1: 25,
          y2: 125,
          z: 0,
          active: false,
          config :  {
            background_color : 'white',
            rounded : 4 ,
          },
        },
      }
    },
  },
  watch : {
    display() {
      if ( this.display !== this.value ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    },
    triggerUpdate() {
      this.initBlock()
    },
    displaySettings(value) {
      if (value ) {
        this.$nextTick(()=>{
          document.getElementById('avalon_block_' + this.block.id).focus()
        })
      }
    },
  },
  methods : {
    ...mapActions('avalonPage',[
      'ADD_ELEMENT',
      'EDIT_BLOCK_DRAFT',
      'DUPLICATE_ELEMENT'
    ]),
    ...mapActions('certificates' , [
      'GET_COURSE_CERTIFICATE_EDIT' ,
      'SAVE_CERTIFICATE' ,
      'ADD_COURSE_CERTIFICATE',
      'DELETE_COURSE_CERTIFICATE'
    ]),

    async deleteCertificate() {

      let data = {
        course_id : this.uuid,
        lang : this.selectedLang
      }
      let result = await this.DELETE_COURSE_CERTIFICATE(data)
      if ( !result ) {
        return this.notify(this.$t('NetworkError') , 'error')
      }
      this.$emit('update')
      this.notify(this.$t('certificates.design.deleted'))
      this.display = false

    },
    async saveCertificate() {
      let result = await this.SAVE_CERTIFICATE(this.block.id)
      if ( !result ) {
        return this.notify(this.$t('NetworkError') , 'error')
      }
      this.display = false
      this.$emit('update')
      return this.notify(this.$t('ChangesSaved'))
    },


    async addElement(type) {
      let data = this.COPY(this.defaultElements[type])
      data.lang = this.selectedLang
      data.block_id = this.block.id
      data.z = this.zIndex + 1

      let result = await this.ADD_ELEMENT(data)
      if ( !result ) {
        return
      }
      this.blockElements.push(result)
      this.blockElements = this.COPY(this.blockElements)

    },
    duplicateElement(result) {
      this.notify(this.$t('avalon.element.duplicate_success'))
      let xLength = result.x2 - result.x1
      let yLength = result.y2 - result.y1

      result.width  = ( xLength * this.cellDimensions.gapHorizontal ) + (( xLength + 1 ) * this.cellDimensions.width  )
      result.height = ( yLength * this.cellDimensions.gapVertical   ) + (( yLength + 1 ) * this.cellDimensions.height )

      this.blockElements.push(result)
      this.blockElements = JSON.parse(JSON.stringify(this.blockElements))
      setTimeout(()=> {
        this.newElementTrigger++
      } , 10)
    },
    updateImage() {
      let div = this.$refs.blockBackground
      setTimeout( () => {
        div.style.backgroundSize = 'cover'
      } , 300 )
    },
    async updateBlock(block) {

      let result = await this.EDIT_BLOCK_DRAFT(block)
      if ( !result ) {
        return
      }
      this.block = this.COPY(block)

    },
    // technical
    layoutStyle(hover = false) {
      let style = 'transition : -webkit-box-shadow 0.3s ease, -moz-box-shadow 0.3s ease, box-shadow 0.3s ease;'

      let sticky = this.header.config.sticky && this.scrollPosition > 52
      let stickyHeight = sticky ? this.header.config.height : 0

      if ( this.MOBILE_VIEW ) {
        stickyHeight = sticky ? this.header.config.height_sm : 0
      }

      if ( sticky && this.overlap ) {
        style += `margin-top : ${stickyHeight}px` + ';'
      }

      if ( this.avalonBlockConfig(this.block, 'background_type' ) === 'color' ) {
        if ( !this.block.config ) {
          style += `background-color : ${ this.avalonConfig.colors.background_main }` + ';'
        } else {
          if ( this.block.config.color && this.avalonConfig.colors[this.block.config.color] ) {
            style += `background-color : ${this.avalonConfig.colors[this.block.config.color]}` + ';'
          } else {
            style += `background-color : ${this.block.config.color}` + ';'
          }
        }
      }
      if ( this.avalonBlockConfig(this.block, 'background_type' ) === 'image' ) {
        style += `background: url('${this.avalonBlockConfig(this.block, 'image' )}');
                  background-size : cover;
                  position:relative;`
        if ( !this.MOBILE_VIEW ) {
          style += `background-position : ${this.avalonBlockConfig(this.block, 'image_position_x' )}% ${this.avalonBlockConfig(this.block, 'image_position_y' )}%;`
        } else {
          style += `background-position : ${this.avalonBlockConfig(this.block, 'image_position_sm_x' )}% ${this.avalonBlockConfig(this.block, 'image_position_sm_y' )}%;`
        }
      }

      if ( (hover || this.edit) && this.editorMode ) {
        style += `-webkit-box-shadow:inset 0px 0px 0px 2px ${this.wsATTENTION};`
        style += `-moz-box-shadow:inset 0px 0px 0px 2px ${this.wsATTENTION};`
        style += `box-shadow:inset 0px 0px 0px 2px ${this.wsATTENTION};`
      }

      return style
    },

    initBlock() {
      this.blockElements = this.COPY(this.elements)
    },
    async initPage() {
      let result = await this.GET_COURSE_CERTIFICATE_EDIT({ uuid : this.uuid , lang : this.$store.state.courses.selectedLang })
      if ( !result ) {
        this.block = {}
        this.elements = []
        return
      }
      if ( !result.certificate ) {
        this.block = {}
        this.elements = []
        return
      }
      this.block = result.certificate.block ?? {}
      this.elements = result.certificate.elements ?? []
    }

  },
  async mounted() {
    this.display = this.value
    await this.initPage()
    this.initBlock()
    if (this.elements.length === 0 ) {
      this.edit = true
    }
  }
}
</script>

<style scoped>

</style>