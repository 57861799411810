<template>
  <div>
    <image-param-picker
        @input="editElement"
        v-model="element.text"
        :vertical="element.config.image_position_y || 50"
        :horizontal="element.config.image_position_x || 50"
        :contain="element.config.contain"
        @vertical="element.config.image_position_y = $event; editElement()"
        @horizontal="element.config.image_position_x = $event; editElement()"
        @contain="element.config.contain = $event; editElement()"
        settings
    />
  </div>


</template>

<script>
import imageParamPicker from "@/components/AvalonEditor/UI/imageParamPicker";
export default {
  name: "certificateImageSettings",
  components : {
    imageParamPicker
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      element : {}
    }
  },
  watch : {
    value() {
      if ( !this.EQ(this.value , this.element ) ) {
        this.element = this.COPY(this.value)
      }
    }
  },
  methods : {
    editElement() {
      this.$emit('input' , this.COPY(this.element) )
    }
  },
  beforeMount() {
    if ( !this.element.config.image_position_y && this.element.config.image_position_y !== 0) {
      this.element.config.image_position_y = 50
      this.editElement()
    }
    if ( !this.element.config.image_position_x && this.element.config.image_position_x !== 0) {
      this.element.config.image_position_x = 50
      this.editElement()
    }
  },
  mounted() {
    this.element = this.value || {}
  }
}
</script>

<style scoped>

</style>