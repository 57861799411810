<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('certificates.admin.title')"
      icon="mdi-certificate"
      fill-height
      :loading="loading"
      :no-content-condition="availableLanguages.length === 0"
      no-content-text="certificates.admin.no_content"
      no-content-button-text="certificates.create"
      no-content-icon="mdi-certificate"
      :no-content-button-action="openNewCertificate"
  >
    <template #default>
      <ws-accordion
          class="mt-5"
          :items="accordionItems"
          :key="updateAccordeon"
      >

        <template #action.active>
          <ft-select @input="changeAutoIssue"  :items="CHOICE_ACTIVE" v-model="autoIssue" isBoolean  >
            <template #default="{text}">
              <v-chip
                  :color="wsACCENT"
                  :style="!autoIssue ? 'opacity : 0.5' : ''"
                  class="px-1 justify-end font-weight-medium pointer mr-6"
                  dark
              >
                <v-icon  class="mr-1" > mdi-check-circle-outline </v-icon>
                <h5 class="mx-2">{{ text }}</h5>
                <v-icon >mdi-menu-down</v-icon>
              </v-chip>
            </template>

          </ft-select>
        </template>

        <template #action.pdf>
          <v-btn height="33"  @click="openDownload" rounded :color="wsACCENT" elevation="0" dark class="noCaps mr-5 pl-3 pr-6">
            <v-icon size="20" class="mr-2">mdi-download</v-icon>
            <h5>
              {{ $t('Download') }}
            </h5>
          </v-btn>
        </template>

        <template #action.design>
          <v-btn height="33"  @click.stop="addEditCertificate" rounded :color="wsACCENT" elevation="0" dark class="noCaps mr-5 pl-3 pr-6">
            <v-icon size="20" class="mr-2">
              {{ block.id ? 'mdi-pencil' : 'mdi-plus' }}
            </v-icon>
            <h5>
              {{ block.id ? $t('Edit') : $t('Create') }}
            </h5>
          </v-btn>
        </template>

        <template #item.design>
          <v-sheet v-if="block.id" :color="wsLIGHTCARD" class="d-flex justify-center">
            <certificate-viewer
                :block="block"
                :elements="elements"
                :trigger-update="updateBlockTrigger"
                :key="updateBlockTrigger"
            />
          </v-sheet>
          <ws-blank-page
              v-else
              :text="$t('certificates.design.language.no_certificate')"
              icon="mdi-certificate"
              :button-text="$t('certificates.create')"
              :button-action="openNewCertificate"
          />
        </template>

        <template #item.issued>
          <issued-certificates :uuid="uuid" />
        </template>

      </ws-accordion>
    </template>

    <template #dialog>

      <!-- New Certificate Dialog-->
      <avalon-new-block-dialog
          v-if="displayNewCertificate"
          @save="newCertificate"
          v-model="displayNewCertificate"
          certificates
      />
      <!-- Download Certificate Dialog-->
      <ws-dialog
          v-model="displayDownload"
          :title="$t('certificates.download')"
          :save-text="$t('Download')"
          :loading="LOADING"
          @save="downloadCertificate"
      >

        <v-row>
          <v-col cols="9">
            <ws-text-field
                v-model="entityData.student"
                :label="$t('StudentName')"
                :placeholder="$t('EnterStudentName')"
            />
          </v-col>
          <v-col cols="3">
            <ws-text-field
                v-model="entityData.result"
                placeholder="99"
                :label="$t('Result')"
                number
                number-length="3"
            />
          </v-col>
          <v-col cols="6">
            <ws-date-picker
                v-model="entityData.date"
                :label="$t('DateStart')"
                :placeholder="$t('EducationStart')"
            />
          </v-col>
          <v-col cols="6">
            <ws-date-picker
                v-model="entityData.date_registered"
                :placeholder="$t('CompletionDate')"
                :label="$t('DateEnd')"
            />
          </v-col>
        </v-row>

        <h5 class="mt-6" :style="`color : ${wsDARKLIGHT}`">
          {{ $t('certificates.admin.download_description') }}
        </h5>

      </ws-dialog>
      <!-- Certificate Editor -->
      <certificateEditor_new
          v-if="displayEditor"
          v-model="displayEditor"
          @update="initPage"
          :uuid="uuid"
      />

    </template>

  </sub-page>
</template>

<script>
import {mapActions, mapMutations , mapState} from "vuex";
import certificateEditor_new
  from "@/components/pages/westudy/admin/course/courseCertificates/editor/certificateEditor_new";
import certificateViewer from "@/components/pages/westudy/admin/course/courseCertificates/view/certificateViewer";
import wsBlankPage from "@/components/UI/wsBlankPage";
import issuedCertificates from "@/components/pages/westudy/admin/course/courseCertificates/issuedCertificates";
import defaultConfig from "@/assets/json/avalon/defaultConfig.json";
import avalonNewBlockDialog from "@/components/AvalonEditor/Dialogs/blocks/avalonNewBlockDialog";
export default {
  name: "courseSettings",
  props : ['uuid'],
  components : {
    certificateViewer,
    wsBlankPage,
    certificateEditor_new,
    issuedCertificates,
    avalonNewBlockDialog
  },
  data() {
    return {
      updateAccordeon : 0,
      chooseFromTemplates : true,
      availableLanguages : [],
      updateBlockTrigger : 0,
      loading : true,
      block : {} ,
      elements : [],
      displayEditor : false,
      displayDownload : false,
      displayNewCertificate : false,
      accordionItems : [],
      sizesSelect : [
        { text : 'A4' , value : 'A4' },
        { text : 'A5' , value : 'A5' }
      ],
      templates : [],
      entityData : {},
      isActive : false,
      autoIssue : false,

    }
  },
  computed : {
    ...mapState('courses', [
        'course' ,
        'selectedLang' ,
    ]),

    accordionItemsSource() {
      return [
        { name : this.$t('certificates.autoissue.title'),
          subtitle : this.$t('certificates.autoissue.text'),
          icon : 'mdi-flash-auto' ,
          value : 'active' ,
          noExpand : true
        },
        { name : this.$t('certificates.pdfPreview.title') ,
          subtitle : this.$t('certificates.pdfPreview.text'),
          value : 'pdf' ,
          noExpand: true ,
          icon : 'mdi-file-pdf-box' ,
          expanded : false
        },
        { name : this.$t('certificates.design.title') ,
          subtitle : this.$t('certificates.design.description'),
          value : 'design' ,
          noExpand: false ,
          icon : 'mdi-palette' ,
          expanded : false
        },
        { name : this.$t('certificates.issued.title') ,
          subtitle : this.$t('certificates.issued.text'),
          value : 'issued' ,
          noExpand: false ,
          icon : 'mdi-certificate' ,
          expanded : false
        },
      ]
    },

    orientationSelect() {
      return [
        { text : this.$t("Landscape") , value : 'landscape' },
        { text : this.$t("Portrait")  , value : 'portrait'}
      ]
    }

  },
  watch : {
    ...mapActions('certificates', ['GET_COURSE_CERTIFICATE_ADMIN']),

    selectedLang() {
      this.initPage()
    },
    'block.id'() {
      this.updateBlockTrigger++
    }

  },
  methods : {
    ...mapMutations('courses', ['INIT_PAGE']),
    ...mapActions('courses', ['ADD_COURSE']),
    ...mapActions('certificates' , [
      'GET_COURSE_CERTIFICATE_EDIT' ,
      'ADD_COURSE_CERTIFICATE',
      'DOWNLOAD_CERTIFICATE_ADMIN',
      'GET_CERTIFICATE_TEMPLATES'
    ]),

    addEditCertificate() {
      if ( this.block.id ) {
        this.displayEditor = true
      } else {
        this.openNewCertificate()
      }
    },
    async changeAutoIssue() {

      let data = {
        uuid : this.uuid,
        issue_certificate_on : this.autoIssue,
        is_from_certificates : true
      }

      let result = await this.ADD_COURSE(data)
      if (!result) {
        this.notify(this.$t('NetworkError') )
        this.autoIssue = this.$store.state.courses.course.issue_certificate_on
        return
      }
      this.notify( this.$t('ChangesSaved') )
      this.$store.state.courses.course.issue_certificate_on = this.autoIssue

    },

    openDownload() {
      this.entityData = {}
      this.displayDownload = true
    },
    async downloadCertificate() {

      let data = {
        user_name : this.entityData.student,
        course_id : this.uuid,
        result : this.entityData.result,
        lang : this.selectedLang
      }
      let result = await this.DOWNLOAD_CERTIFICATE_ADMIN(data)
      if ( !result ) {
        return
      }

      var url  = window.URL.createObjectURL(result);
      var a = document.createElement('a');
      a.href = url;
      a.download = 'certificate.pdf';
      document.body.appendChild(a);
      a.click();

    },

    async openNewCertificate() {
      let result = await this.GET_CERTIFICATE_TEMPLATES()
      this.entityData = {
      }
      if ( result && result !== true ) {
        this.templates = result
      }
      this.displayNewCertificate = true
      this.entityData = {
        course_id : this.uuid,
        lang : this.selectedLang,
        template_id : null,
      }
    },
    async newCertificate($event) {

      if ($event && $event.id) {
        this.entityData.template_id = $event.id
      }

      let result = await this.ADD_COURSE_CERTIFICATE(this.entityData)
      if ( !result ) {
        return
      }
      this.block = result.block
      this.elements = result.elements
      this.notify(this.$t('certificates.created') , 'success')
      this.initPage()
      this.displayNewCertificate = false
      this.accordionItems[2].expanded = true
      this.accordionItems = JSON.parse(JSON.stringify(this.accordionItems))
      this.updateAccordeon++
    },

    async initPage() {

      let result = await this.GET_COURSE_CERTIFICATE_EDIT({ uuid : this.uuid , lang : this.$store.state.courses.selectedLang })
      if ( !result ) {
        this.block = {}
        this.elements = []
        this.availableLanguages = []
        return
      }
      if ( result.certificate ) {
        this.block = result.certificate.block
        this.elements = result.certificate.elements
        this.updateBlockTrigger++
      } else {
        this.block = {}
        this.elements = []

      }
      this.availableLanguages = result.available_languages || []
    }

  },
  beforeMount() {
    this.$store.state.avalon.defaultConfig = defaultConfig
  },
  async mounted() {
    this.autoIssue = this.$store.state.courses.course.issue_certificate_on
    this.loading = true
    await this.initPage()
    this.loading = false
    this.accordionItems = this.accordionItemsSource

  }
}
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.card-container {
  width: 100%;
}

.card {
  background-color: red;
  width: 100%;
  height: 100%;
  transform: scale(0.33);
  transform-origin: top left;
  text-align: center;
  padding: 20px;
}
</style>