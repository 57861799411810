<template>
  <ws-dialog
      v-model="display"
      :title="$t('Edit')"
      style="z-index: 500 !important;"
      overlay-color="transparent"
      width="320"
      fullscreen-align="right"
      not-centered
      no-navigation
      margin-right="25px"
      margin-top="68px"
      margin-bottom="25px"
      border-radius="8"
      blank
  >
    <template #header>
      <h4 class="py-4 px-6">{{ $t('GeneralSettings') }}</h4>
    </template>
    <v-sheet :id="'avalon_block_' + block.id" min-height="500" >
      <div >
        <a-parameter-select
            class="mt-3"
            @input="updateBlock"
            v-model="block.is_vertical"
            :items="orientationSelect"
            label="Orientation"
            :label-color="wsDARKER"
            :value-color="wsACCENT"
            null-text="Default"
            light
        />
        <v-divider
            class="mt-3 pb-3"
            :style="`border-color : ${wsDARKLIGHT}`"
        />

        <color-param-picker
            @input="updateBlock"
            v-model="block.config.color"
            label="BackgroundColor"
            :label-color="wsDARKER"
            class="pt-2"
            divider
            font-regular
            return-color
        />

        <a-parameter-switch
            @input="updateBlock"
            v-model="block.is_template"
            label="avalon.page.editor.is_template.title"
            class="mt-6"
            light
        />

        <v-divider class="mt-3 mb-6" :style="`border-color : ${wsDARKLIGHT}; `" />

        <ws-accordion
            class="mt-6"
            :items="imageAccordeonItems">
          <template #item.image>
            <image-param-picker
                @input="updateBackgroundImage"
                class="pa-4"
                v-model="block.config.image"
            />
          </template>
        </ws-accordion>

      </div>



    </v-sheet>
  </ws-dialog>

</template>

<script>

import imageParamPicker from "@/components/AvalonEditor/UI/imageParamPicker";
import colorParamPicker from "@/components/AvalonEditor/UI/colorParamPicker";
export default {
  name: "avalonBlocSettings",
  components : {
    colorParamPicker,
    imageParamPicker
  },
  props : {
    value : {
      type : Boolean,
      default : false,
    },
    block : {
      type : Object,
      default() { return {} }
    },
    maxHeight : {
      type : Number,
      default : 0,
    },
    maxHeightSM : {
      type : Number,
      default : 0,
    }
  },
  data() {
    return {
      display : false,
      preventClose : false
    }
  },
  computed : {

    imageAccordeonItems() {
      return [{name : this.$t('Image') , value : 'image'}]
    },
    orientationSelect() {
      return [
        { text : this.$t('Landscape') , value : false , icon : 'mdi-crop-landscape' },
        { text : this.$t('Portrait') , value : true , icon : 'mdi-crop-portrait'  },
      ]
    },
    backgroundTypes() {
      return [
        { text : this.$t('Color') , value : 'color' , icon : 'mdi-palette'},
        { text : this.$t('Image') , value : 'image' , icon : 'mdi-image'},
      ]
    }
  },
  watch : {
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    },
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input',this.display)
      }
    }
  },
  methods : {
    updateBackgroundImage($event) {

      this.block.config.background_type = $event ? 'image' :  'color'
      this.block.config.image = $event
      this.updateBlock(true)
      this.$emit('updateImage')

    },
    updateImage($event) {
      this.block.config.image = $event.url
      this.updateBlock(true)
    },
    async updateBlock(updateImage) {
      this.block = this.COPY(this.block)
      this.$emit('update',this.block)
      if ( updateImage ) {
        this.$emit('updateImage')
      }
    }
  },
  mounted() {
    this.display = this.value
    if (!this.block.config) {
      this.block.config = {
        background_type : this.avalonConfig.block.background_type,
        full_width : this.avalonConfig.block.full_width,
        vertical_padding : this.avalonConfig.block.vertical_padding
      }
    }

    if ( ![true,false].includes(this.block.config.visibility_mobile)) {
      this.block.config.visibility_mobile = true
    }
    if ( ![true,false].includes(this.block.config.visibility_desktop)) {
      this.block.config.visibility_desktop = true
    }
    this.block.config = Object.assign({},this.block.config)

  }
}
</script>

<style scoped>
.block-style {
  position:fixed;
  outline: none;
  top:0;bottom: 0;
  right: 0;
  height: 100% ;
  z-index:1111
}
</style>