<template>
  <div class="d-flex">

    <ft-select :value="element.config.font_size || 14"
               :items="sizeSelect"
               @input="$emit('editFontSize' , $event)"
               @expand="$emit('dropdown' , $event)"
    >
      <v-btn :color="wsACCENT" icon >
        <h5> {{ element.config.font_size || 14 }}</h5>
      </v-btn>
    </ft-select>

    <v-menu v-model="colorDropdown"   key="color_picker_menu"  :close-on-content-click="false" offset-y>
      <template v-slot:activator="{attrs,on}">
        <v-btn class="px-0 mx-0" :color="element.config.color" v-bind="attrs" v-on="on" text min-width="35" width="35">
          <v-icon>mdi-format-text-variant-outline</v-icon>
        </v-btn>
      </template>
      <v-sheet  class="pa-2 salvation-toolbar">
        <a-color-picker
            key="color_picker"
            @input="$emit('editFontColor' )"
            :color-set="$store.state.newsletter.colorSet"
            v-model="element.config.color"
        />
      </v-sheet>
    </v-menu>


      <v-btn
          v-for="(item, i ) in toolbarItemsSelect" :key="i"
          @click="$emit('action' , item.value)"
          :color="config[item.value] ? wsBACKGROUND : wsACCENT"
          class="px-0 mx-0"
          min-width="35"
          elevation="0"
          width="35"
          :text="!config[item.value]"
      >
        <v-icon :color="wsACCENT">{{  item.icon  }} </v-icon>
      </v-btn>
  <v-divider class="py-1" vertical />
  </div>

</template>

<script>
export default {
  name: "certificateTextToolbar",
  props : {
    element : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      colorDropdown : false,
      size : 14,
      toolbarItemsSelect : [
        { value : "strong"         , icon : "mdi-format-bold"          } ,
        { value : "italic"         , icon : "mdi-format-italic"        } ,
        { value : "underline"      , icon : "mdi-format-underline"     } ,
        { value : "left"     , icon : "mdi-format-align-left"    } ,
        { value : "center"   , icon : "mdi-format-align-center"  } ,
        { value : "right"    , icon : "mdi-format-align-right"   } ,
      ]
    }
  },
  computed : {
    config() {
      return this.element.config || {}
    },
    sizeSelect() {
      return [
        { text : 10 , value : 10 },
        { text : 12 , value : 12 },
        { text : 16 , value : 16 },
        { text : 18 , value : 18 },
        { text : 20 , value : 20 },
        { text : 22 , value : 22 },
        { text : 24 , value : 24 },
        { text : 26 , value : 26 },
        { text : 28 , value : 28 },
        { text : 32 , value : 32 },
        { text : 48 , value : 48 },
      ]
    },
  },
  watch : {
    colorDropdown(value) {
      this.$emit('dropdown' , value)
    }
  }
}
</script>

<style scoped>

</style>