<template>
  <VueDraggableResizable

      :w="width || 1" :h="height || 0" :x="x || 0" :y="y || 0" :z="z ||0"
      class-name-handle="vdr-handle"
      class-name-active="vdr-active"
      class-name-vdr="vdr"
      @resizing="handleResize"
      @dragging="handleDrag"
      @resizestop="editElementDimensions"
      @dragstop="editElementDimensions"
      parent

      :draggable="false"

      @activated="isSelected = true"
      @deactivated="isSelected = false"

      :prevent-deactivation="displaySettings || toolbarOpened"
      :lockAspectRatio="aspectRatioOn"
      :aspectRatio="1"
      style="transform-style: preserve-3d;"
      ref="draggable"
  >

    <portal to="element_settings">
      <certificate-element-settings
          @close="displaySettings = false "
          v-if="displaySettings"
          v-model="displaySettings"
          :element="element"
          @update="editElement($event)"
      />
    </portal>

    <editElementToolbar
        @action="toolbarAction"
        :display="isSelected && !moving"
        :noEdit="element.type === 'qrcode'"
        @dropdown="toolbarOpened = $event;"
        @mouseOverToolbar="mouseOverToolbar = $event"
    >
      <template v-if="element.type === 'text'">
        <certificate-text-toolbar
            @action="textToolbarAction"
            @editFontSize="editTextSize"
            @dropdown="toolbarOpened = $event"
            :element="element"
        />
      </template>
    </editElementToolbar>

    <v-sheet  class="fill-height"  @mousedown="startDrag" color="transparent">
      <certificateTextEdit


          v-if="element.type === 'text' "
          :is-dragging="isDragging"
          :is-selected="isSelected"
          @mouseOverText="mouseOverToolbar = $event"
          :updateTrigger="textUpdateTrigger"
          v-model="element"
      />
      <certificateQRCode
          v-if="element.type === 'qrcode' "
          v-model="element"
      />
      <element-image-editor
          v-if="element.type === 'image' "
          v-model="element"
          :template="template"
      />
      <element-rectangle-editor
          v-if="element.type === 'rectangle' "
          v-model="element"
      />
      <element-line-editor
          v-if="element.type === 'line' "
          v-model="element"
      />
    </v-sheet>


  </VueDraggableResizable>
</template>

<script>

import certificateTextToolbar
  from "@/components/pages/westudy/admin/course/courseCertificates/editor/certificateTextToolbar";
import certificateElementSettings
  from "@/components/pages/westudy/admin/course/courseCertificates/editor/certificateElementSettings";
import editElementToolbar from "@/components/AvalonEditor/Editor/elements/editElementToolbar";
import {mapActions, mapState} from "vuex";
import VueDraggableResizable from 'vue-draggable-resizable'
import certificateTextEdit from "@/components/pages/westudy/admin/course/courseCertificates/editor/certificateTextEdit";
import elementImageEditor from "@/components/AvalonEditor/Editor/elements/elementImageEditor";
import elementRectangleEditor from "@/components/AvalonEditor/Editor/elements/elementRectangleEditor";
import elementLineEditor from "@/components/AvalonEditor/Editor/elements/elementLineEditor";
import certificateQRCode from "@/components/pages/westudy/admin/course/courseCertificates/editor/certificateQRCode";
export default {
  name: "certificateCanvasElement",
  components : {
    VueDraggableResizable,
    certificateTextEdit,
    elementImageEditor,
    elementRectangleEditor,
    elementLineEditor,
    certificateQRCode,
    certificateTextToolbar,
    certificateElementSettings,
    editElementToolbar
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    guidesX : {
      type : Array,
      default() { return [] }
    },
    guidesY : {
      type : Array,
      default() { return [] }
    },
    maxHeight : {
      type : Number,
      default : 500,
    },
    maxWidth : {
      type : Number,
      default : 500,
    },
    guideThreshold : {
      type : Number,
      default : 10
    }
  },
  data() {
    return {
      mouseOverToolbar : false,
      displaySettings : false,
      isSelected : false,
      element : {},
      x : 0,
      y : 0,
      z : 0,
      width : 0,
      height : 15,
      moving : false,
      toolbarOpened : false,
      textUpdateTrigger : 0,
      isDragging : false,
      aspectRatioOn : false,
      dragOn : true,
      startX : 0,
      starty : 0,
      initialX : 0,
      initialY : 0
    }
  },
  computed : {
    ...mapState('certificates' , ['selectedElementId']),
    config() {
      return this.element.config || {}
    }
  },
  watch : {
    value : {
      handler() {
        if ( !this.EQ(this.value , this.element) ) {
          this.element = this.initElement(this.value)
        }
      },
      deep : true
    },
    element : {
      handler() {
        if ( !this.EQ(this.value , this.element) ) {
          this.$emit('input' , this.COPY(this.element) )
          this.editElement()
        }
      },
      deep : true
    },
  },
  methods : {
    ...mapActions('avalonPage',[
      'EDIT_ELEMENT'
    ]),
    editTextSize($event) {
      this.element.config.font_size = $event
      this.element = this.COPY(this.element)
      this.textUpdateTrigger++
    },
    textToolbarAction($event) {

      this.element.config.left = false
      this.element.config.right = false
      this.element.config.center = false
      if ( !this.element.config[$event] ) {
        this.element.config[$event] = true
      } else {
        this.element.config[$event] = false
      }

      if ( ['left' , 'right' , 'center' ].includes($event)) {
        this.element.config.align = $event
      }
      this.element = this.COPY(this.element)
    },
    toolbarAction($event) {
      this.$emit('toolbarAction' , { action : $event , id : this.element.id , element : this.element })

      if ( $event === 'edit') {
        this.displaySettings = true
      }
      if ( $event === 'layer+' ) {
        this.element.z++
      }
      if ( $event === 'layer-' ) {
        this.element.z--
      }
      this.element = this.COPY(this.element)
    },
    editElementDimensions() {
      this.isDragging = false
      this.element.x1 = this.x
      this.element.x2 = this.x + this.width
      this.element.y1 = this.y
      this.element.y2 = this.y + this.height
      this.moving = false
      this.$emit('moving' , false)

    },
    editElement(element = null) {
      this.EDIT_ELEMENT(element || this.element)
      this.$emit('update' , this.COPY(element || this.element) )
      this.element = this.COPY(element || this.element)
    },
    initElement(input) {
      let element = this.COPY(input)

      this.x = element.x1
      this.y = element.y1
      this.z = element.z

      this.width = element.x2 - element.x1
      this.height = element.y2 - element.y1

      return element
    },

    //  technical
    handleResize(x, y, width, height  ) {
      this.x = x
      this.y = y
      this.width = width
      this.height = height
      this.moving = true
      this.$emit('moving' , true)
    },
    startDrag(event) {
      window.addEventListener('mousemove', this.handleDrag);
      window.addEventListener('mouseup', this.stopDrag);
      this.isDragging = true
      this.startX = event.clientX
      this.startY = event.clientY
      this.initialX = this.x
      this.initialY = this.y
    },
    stopDrag() {
      this.isDragging = false
      window.removeEventListener('mousemove', this.handleDrag);
      window.removeEventListener('mouseup', this.stopDrag);
      this.editElementDimensions()
      this.$emit('guide-x' , null )
      this.$emit('guide-y' , null )
    },
    handleDrag(event) {
      if ( !this.isDragging ) {
        return
      }

      let deltaX = event.clientX - this.startX
      let deltaY = event.clientY - this.startY
      let x = this.initialX + deltaX
      let y = this.initialY + deltaY


      if ( x < 0 ) {
        x = 0
      }
      if ( x + this.width > this.maxWidth ) {
        x = this.maxWidth
      }
      if ( y < 0 ) {
        y = 0
      }

      if ( y + this.height > this.maxHeight ) {
        y = this.maxHeight
      }

      let guideX = null
      let guideY = null
      let rightGuide = null
      let bottomGuide = null
      if ( this.guidesX.length > 0 ) {

        guideX = this.guidesX.find( el => (el.x > (x - this.guideThreshold) && el.x < (x + this.guideThreshold) )  && el.id !== this.element.id )
        if ( !guideX ) {
          guideX = this.guidesX.find( el => (el.x > (x + this.width - this.guideThreshold) && el.x  < (x + this.width + this.guideThreshold) )  && el.id !== this.element.id )
          rightGuide = true
        }
        this.$emit('guide-x' , guideX ? guideX.x : null )
      }
      if ( this.guidesY.length > 0 ) {
        guideY = this.guidesY.find( el => (el.y > (y - this.guideThreshold) && el.y < (y + this.guideThreshold) )  && el.id !== this.element.id )
        if ( !guideY ) {
          guideY = this.guidesY.find( el => (el.y > (y + this.height - this.guideThreshold) && el.y  < (y + this.height + this.guideThreshold) )  && el.id !== this.element.id )
          bottomGuide = true
        }
        this.$emit('guide-y' , guideY ? guideY.y : null )
      }


      this.x = guideX ? (rightGuide ? guideX.x - this.width : guideX.x ) : x
      this.y = guideY ? (bottomGuide ? guideY.y - this.height : guideY.y ) : y

      this.moving = true
      this.$emit('moving' , true)

    },

  },
  mounted() {
    if ( this.value ) {
      this.element = this.initElement(this.value)
    }
    if ( this.element.type === 'qrcode') {
     setTimeout(()=> {
       this.aspectRatioOn = true
     })
    }
  }


}
</script>

<style scoped>

</style>