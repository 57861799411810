<template>
  <v-sheet
      :min-height="!block.is_vertical ? 564 : 1128"
      :height="!block.is_vertical ? 564 : 1128"
      color="transparent"
      width="800"
      style="transform-style: preserve-3d;"
  >


    <v-sheet color="transparent" class="fill-height" style="position:relative;transform-style: preserve-3d;">
      <v-sheet v-if="currentGuideX" width="1" class="fill-height" :style="`left : ${currentGuideX}px`" style="position: absolute;" :color="wsBACKGROUND" />
      <v-sheet v-if="currentGuideY" width="100%" height="1" :style="`top : ${currentGuideY}px`" style="position: absolute;" :color="wsBACKGROUND" />

      <certificate-canvas-element
          v-for="(_ , i ) in items" :key="'element' + i"
          v-model="items[i]"
          @update="items[i] = $event"
          @click.stop
          @moving="$emit('moving' , $event)"
          @toolbarAction="elementToolbarAction"
          :guides-x="guidesX"
          :guides-y="guidesY"
          @guide-x="currentGuideX = $event"
          @guide-y="currentGuideY = $event"
          :max-height="!block.is_vertical ? 564 : 1128"
          :max-width="800"
          style="position: absolute"
      />
    </v-sheet>



    <ws-dialog v-model="displayConfirmDeleteElement"
               :title="$t('avalon.element.delete')"
               @save="deleteElement($event)"
               display-confirm-delete
               @delete="deleteElement($event)"
    />

  </v-sheet>
</template>

<script>
import certificateCanvasElement
  from "@/components/pages/westudy/admin/course/courseCertificates/editor/certificateCanvasElement";
import {mapActions} from "vuex";
export default {
  name: "CertificateCanvas",
  components : {
    certificateCanvasElement
  },
  data() {
    return {
      displayConfirmDeleteElement : false,
      items : [],
      entity : {},
      selectedElement : null,
      currentGuideX : null,
      currentGuideY : null
    }
  },
  props : {
    value : {
      type : Array,
      default() { return [] }
    },
    block : {
      type : Object,
      default() { return {} }
    },

  },
  computed : {
    guidesX() {
      if ( !this.items ) {
        return []
      }
      let array =  this.items.map( el=> ({x1 : el.x1 , x2 : el.x2, y1 : el.y1, y2 : el.y2 , id : el.id }))
      let items = []
      array.forEach(el => {
        items.push(
            { x : el.x1 , id : el.id , l : true },
            { x : el.x2 , id : el.id , r : true },
        )
      })

      return items

    },
    guidesY() {
      let array =  this.items.map( el=> ({x1 : el.x1 , x2 : el.x2, y1 : el.y1, y2 : el.y2 , id : el.id }))
      let items = []
      array.forEach(el => {
        items.push(
            { y : el.y1 , id : el.id , t : true },
            { y : el.y2 , id : el.id , b : true }
        )
      })

      return items

    }
  },
  watch : {
    value : {
      handler() {
        if ( !this.EQ(this.items,this.value) ) {
          this.items = JSON.parse(JSON.stringify(this.value))
        }
      },
      deep : true
    },
    items : {
      handler() {
      if ( !this.EQ(this.items,this.value) ) {
          this.$emit('input' , this.COPY(this.items) )
        }
      },
      deep : true
    },
    entity : {
      handler() {
        if ( JSON.stringify(this.entity) !== JSON.stringify(this.block) ) {
          this.entity = JSON.parse(JSON.stringify(this.block))
        }
      },
      deep : true
    },
  },
  methods : {

    ...mapActions('avalonPage',[ 'DELETE_ELEMENT',  'DUPLICATE_ELEMENT' ]),

    async duplicateElement(element) {
      let data = this.COPY(element)

      data.y1 += 20
      data.y2 += 20

      let result = await this.DUPLICATE_ELEMENT(data)
      if ( !result ) {
        return
      }

      this.items.push(result)
      this.$emit('input' , this.COPY(this.items))


    },

    async deleteElement() {
      let result = await this.DELETE_ELEMENT(this.selectedElement)
      if (!result) {
        this.displayConfirmDeleteElement = false
        return this.notify(this.$t("Error"))
      }
      let index = this.items.findIndex(el => el.id === this.selectedElement)
      if (index !== -1) {
        this.items.splice(index, 1)
      }
      this.$emit('input' , this.COPY(this.items))
      this.displayConfirmDeleteElement = false
    },
    elementToolbarAction($event) {
      this.selectedElement = $event.id
      if ( $event.action === 'delete' ) {
        this.displayConfirmDeleteElement = true
      }
      if ( $event.action === 'duplicate' ) {
        this.duplicateElement($event.element)
      }
    },
  },
  mounted() {
    if ( this.value) {
      this.items = this.COPY(this.elements)
    }
    if ( this.block) {
      this.entity = this.COPY(this.entity)
    }
  }
}
</script>

<style scoped>

</style>