<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <h5> {{ $t('Align') }}</h5><v-sheet class="wsRoundedLight d-flex align-center" :style="`border : 1px solid ${wsBACKGROUND}`">
      <v-btn @click="editLineValign('top')" icon>
        <v-icon  :color="element.config.valign === 'top' ? wsACCENT : 'grey'">
          mdi-format-vertical-align-top
        </v-icon>
      </v-btn>
      <v-divider vertical />
      <v-btn @click="editLineValign('center')"  icon>
        <v-icon :color="element.config.valign === 'center' || !element.config.valign ? wsACCENT : 'grey'  ">
          mdi-format-vertical-align-center
        </v-icon>
      </v-btn>
      <v-divider vertical />
      <v-btn @click="editLineValign('bottom')" icon>
        <v-icon :color="element.config.valign === 'bottom' ? wsACCENT : 'grey'">mdi-format-vertical-align-bottom
        </v-icon>
      </v-btn>
    </v-sheet>
    </div>

    <a-parameter-switch
        @input="editElement"
        v-model="element.config.is_dynamic"
        label="DynamicText"
        class="mt-6"
        light
    />
    <v-divider class="mt-3 mb-6" :style="`border-color : ${wsDARKLIGHT}; `" />

    <v-expand-transition>
      <div v-if="element.config.is_dynamic" class="pt-3">
        <a-parameter-select
            @input="editElement"
            v-model="element.config.content_type"
            :items="dynamicTextTypeSelect"
            label="Type"
            :label-color="wsDARKER"
            :value-color="wsACCENT"
            null-text="Default"
            light
        />
        <v-divider class="mt-3 mb-6" :style="`border-color : ${wsDARKLIGHT}; `" />
      </div>

    </v-expand-transition>


  </div>


</template>

<script>

export default {
  name: "certificateImageSettings",
  components : {
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      element : {}
    }
  },
  computed : {
    dynamicTextTypeSelect() {
      return [
        { text : this.$t('SchoolName') , value : 'business_name' },
        { text : this.$t('StudentName') , value : 'student_name' },
        { text : this.$t('Date') , value : 'date' },
        { text : this.$t('CourseName') , value : 'course_name' },
        { text : this.$t('Result') , value : 'result' },
        { text : this.$t('CertificateNumber') , value : 'certificate_number' },
      ]
    }
  },
  watch : {
    value() {
      if ( !this.EQ(this.value , this.element ) ) {
        this.element = this.COPY(this.value)
      }
    }
  },
  methods : {
    editLineValign(value) {
      this.element.config.valign = value
      this.editElement()
    },
    editElement() {
      this.$emit('input' , this.COPY(this.element) )
    }
  },
  mounted() {
    this.element = this.value || {}
  }
}
</script>

<style scoped>

</style>