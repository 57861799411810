<template>
  <v-sheet class="fill-height" >

    <div v-if="items.length" class="d-flex justify-space-between align-center pa-3 px-5">
      <h4> {{ $t('CertificatesIssued') }} : {{ items.length}}</h4>
      <ws-text-field v-model="search" icon="mdi-magnify" clearable />
    </div>

    <ws-data-table
        v-if="items.length"
        :items="items"
        :search="search"
        :headers="headers"
        :row-action="openEdit"
    >

      <template #item.user_name="{item}">
        <h5 :style="`color : ${wsACCENT}`">
          {{ item.user_name  }}
        </h5>
      </template>


      <template #item.number="{item}">
        <h5> {{ item.number  }} </h5>
      </template>

      <template #item.date="{item}">
        <h5 class="text-no-wrap"> {{ PARSE_DATE(item.date , false,true,true)  }} </h5>
      </template>

      <template #item.action="{item , hover}">
        <div>
          <v-fade-transition>
            <v-btn v-if="hover" :color="wsACCENT" @click.stop="openDelete(item)" icon>
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </v-fade-transition>
        </div>
      </template>

      <template #footer.prepend>
        <ws-button class="mb-3" block text v-if="items.length" left-icon="mdi-plus" @click="openNewCertificate" label="IssueCertificate" />
      </template>

    </ws-data-table>

    <v-sheet min-height="500" class=" d-flex align-center justify-center" v-else>

      <div>
        <div class="d-flex justify-center">
          <v-icon size="80" :color="wsDARKLIGHT" >mdi-cog</v-icon>
        </div>
        <h3 style="font-size: 22px; font-weight: 700" class="text-center  my-6">
          {{ $t('NoIssuedCertificates') }}
        </h3>
        <div class="d-flex justify-center">
          <ws-button @click="openNewCertificate" label="IssueCertificate"></ws-button>
        </div>
      </div>



    </v-sheet>

    <!-- View issued certificate dialog -->
    <ws-dialog
        @save="editEntity"
        v-if="displayDialog"
        v-model="displayDialog"
        :title="$t(!newCertificate ? 'EditCertificate' : 'IssueCertificate')"
        show-delete
        :cancel-text="$t('Download')"
        cancel-action
        @delete="deleteCertificate"
    >

      <template #buttons>
        <ft-select
            :items="availableLangs"
            v-model="downloadLang"
            :disabled="availableLangs.length < 2 || newCertificate"
            @input="downloadCertificate($event)"
        >
          <ws-button
              @click="handleCloseButton"
              :click-stop="false"
              :label="cancelText"
              outlined
              :disabled="LOADING"
              :loading="LOADING"
              class="mr-2"
          />
        </ft-select>

        <ws-button
            @click="editEntity"
            label="Save"
            :loading="LOADING"
            :disabled="LOADING"
        />
      </template>



      <ws-text-field
          v-model="entityData.number"
          :label="$t('Number')"
          :placeholder="$t('certificates.edit_certificate.enter_number')"
          class="mt-5"
          :error="numberError"
          @input="numberError = false"
      />




      <v-row class="my-5" no-gutters >
        <v-col class="pr-1">
          <ws-date-picker
              v-model="entityData.date_registered"
              :label="$t('EnrolledOnCourse')"
              :placeholder="$t('certificates.edit_certificate.choose_enrolled_date')"
              :error="dateRegisteredError"
              @input="dateRegisteredError = false"
              time-stamp
          />
        </v-col>
        <v-col class="pl-1">
          <ws-date-picker
              v-model="entityData.date"
              :label="$t('CertificateIssued')"
              :placeholder="$t('certificates.edit_certificate.issued_data')"
              :error="dateError"
              @input="dateError = null"
              time-stamp
          />
        </v-col>
      </v-row>

      <ws-select
          @input="handleUserSelect"
          v-if="newCertificate"
          v-model="entityData.user_id"
          :items="usersSelect"
          :label="$t('User')"
          :placeholder="$t('ChooseUser')"
          autocomplete
          class="mb-5"
      />

      <template v-if="entityData.translations.length === 0 " >

        <ws-text-field
            v-model="entityData.user_name"
            :label="$t('HumanName')"
            :placeholder="$t('EnterHumanName')"
            clearable
        />

        <ws-text-field
            v-model="entityData.course_name"
            :label="$t('CourseName')"
            :placeholder="$t('CourseNameIsEmpty')"
            clearable
            class="mt-5"
        />

      </template>

      <template v-else>


        <div :style="`border : 1px solid ${wsBACKGROUND}`" class="px-5 py-3 pb-5 wsRoundedLight mt-3" v-for="(item , i) in entityData.translations" :key="i" >

          <div class="d-flex justify-space-between align-center">
            <h4 :style="`color : ${wsACCENT}`">{{ LANGUAGE_TEXT(item.lang) }}</h4>
            <v-btn @click="deleteTranslation(item.uuid , i)" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>

          <ws-text-field
              v-model="entityData.translations[i].user_name"
              :label="$t('HumanName')"
              :placeholder="$t('EnterHumanName')"
              clearable
          />

          <ws-text-field
              v-model="entityData.translations[i].course_name"
              :label="$t('CourseName')"
              :placeholder="$t('CourseNameIsEmpty')"
              clearable
              class="mt-5"
          />
        </div>


      </template>

      <ft-select
          @input="addTranslation"
          v-if="langsSelect.length > 0"
          :items="langsSelect"
      >
        <ws-button
            class="mt-5"
            block
            text
            label="AddTranslation"
            :click-stop="false"
            icon="mdi-menu-down"
        />
      </ft-select>




    </ws-dialog>
    <!-- Delete certificate dialog -->
    <ws-dialog
        v-if="displayDeleteDialog"
        v-model="displayDeleteDialog"
        :title="$t('DeleteCertificate')"
        display-confirm-delete
        close-delete
        @delete="deleteCertificate"
    />


  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";
import languagesList from "@/mixins/languagesList";

export default {
  name: "issuedCertificates",
  mixins : [languagesList],
  props : {
    uuid : String,
  },
  data() {
    return {
      items : [],
      displayDialog : false,
      selectedCertificate : null,
      entityData : {},
      translations : [],
      displayDeleteDialog : false,
      currentLang : null,
      search : '',
      usersSelect : [],
      newCertificate : false,

      numberError : false,
      dateRegisteredError : false,
      dateError : false,
      downloadLang : null
    }
  },
  computed : {
    cancelText() {
      return this.newCertificate ? this.$t('Cancel') : this.$t('Download')
    },
    availableLangs() {
      let items = []
      if ( this.entityData.translations && this.entityData.translations.length > 0 ) {
        let langs = this.$store.state.courses.course.langs || ['ua']

        langs.forEach(lang => {
          items.push({ value : lang , text : this.LANGUAGE_TEXT(lang)})
        })


        items = items.filter( el => this.entityData.translations.map(el => el.lang ).includes(el.value) )
      }

      return items
    },
    langsSelect() {
      let items = []
      let langs = this.$store.state.courses.course.langs || ['ua']

      langs.forEach(lang => {
        items.push({ value : lang , text : this.LANGUAGE_TEXT(lang)})
      })

      if ( this.entityData.translations && this.entityData.translations.length > 0 ) {
        items = items.filter( el => !this.entityData.translations.map(el => el.lang ).includes(el.value) )
      }

      return items
    },
    headers() {
      return [
        { text : this.$t('Student') , value : 'user_name' },
        { text : this.$t('CertificateNumber') , value : 'number', width : 150 },
        { text : this.$t('Date') , value : 'date' , width : 150},
        { value : 'action' , width : 70}
      ]
    }
  },
  methods : {
    ...mapActions('certificates' , [
      'GET_COURSE_ISSUED_CERTIFICATES' ,
      'GET_CERTIFICATE_DATA_EDIT',
      'EDIT_CERTIFICATE_DATA',
      'DELETE_CERTIFICATE_DATA',
      'DELETE_CERTIFICATE_DATA_TRANSLATION',
      'DOWNLOAD_CERTIFICATE_ADMIN',
    ]),
    ...mapActions('user', ['GET_BUSINESS_USERS_SELECT']),

    handleCloseButton(lang = null) {
      if ( !this.newCertificate ) {
        if ( this.availableLangs.length > 1 ) {
          return
        }
        this.downloadCertificate(lang)
      } else{
        this.displayDialog = false
      }
    },
    async downloadCertificate(lang = null) {
      let data = {
        certificate_id : this.entityData.uuid,
        user_id : this.entityData.user_id,
        lang : lang
      }
      let result = await this.DOWNLOAD_CERTIFICATE_ADMIN(data)
      if ( !result ) {
        return
      }

      var url  = window.URL.createObjectURL(result);
      var a = document.createElement('a');
      a.href = url;
      a.download = 'certificate.pdf';
      document.body.appendChild(a);
      a.click();
    },
    handleUserSelect(value) {
      let user = this.usersSelect.find(el => el.value === value )
      if ( !user ) {
        return
      }
      if ( this.entityData.translations.length > 0 ) {
        this.entityData.translations[0].user_name = user.text
      } else {
        this.entityData.user_name = user.text
      }
    },

    async deleteTranslation(uuid , index) {
      if ( uuid ) {
        let result = await  this.DELETE_CERTIFICATE_DATA_TRANSLATION(uuid)
        if ( !result ) {
          return
        }
      }
      this.entityData.translations.splice(index , 1)
    },

    addTranslation(lang) {
      this.entityData.translations.push({
        lang : lang,
        course_name : '',
        user_name : ''
      })
      this.entityData = this.COPY(this.entityData)
    },

    openDelete(item) {
      this.selectedCertificate = this.COPY(item)
      this.displayDeleteDialog = true
    },
    async deleteCertificate() {
      let result = await this.DELETE_CERTIFICATE_DATA(this.selectedCertificate.uuid)
      if ( !result ) {
        return
      }

      let index = this.items.findIndex(el => el.uuid === this.selectedCertificate.uuid )
      if ( index === -1 ) {
        return
      }
      this.items.splice(index , 1)
      this.displayDeleteDialog = false
      this.displayDialog = false
      this.notify(this.$t('CertificateDeleted'))
    },

    async editEntity() {

      if ( !this.entityData.number ) {
        this.numberError = true
        return this.notify(this.$t('certificates.edit_certificate.enter_number') , 'warning')
      }

      if ( !this.entityData.date_registered ) {
        this.dateRegisteredError = true
        return this.notify(this.$t('certificates.edit_certificate.choose_enrolled_date') , 'warning')
      }

      if ( !this.entityData.date ) {
        this.dateError = true
        return this.notify(this.$t('certificates.edit_certificate.issued_data') , 'warning')
      }


      let result = await this.EDIT_CERTIFICATE_DATA(this.entityData)
      if ( !result ) {
        return
      }

      if ( !this.newCertificate ) {
        let index = this.items.findIndex(el => el.uuid === this.selectedCertificate.uuid )
        if ( index === -1 ) {
          return
        }
        this.items[index] = result
        this.items = this.COPY(this.items)
      } else {
        this.items.unshift(result)
      }


      this.displayDialog = false


    },
    async openNewCertificate() {
      this.newCertificate = true
      this.entityData = {
        translations : [],
        course_id : this.$store.state.courses.course.uuid,
      }
      if ( this.langsSelect.length > 0) {
        this.entityData.translations.push({
          course_name : this.$store.state.courses.course.name,
          user_name : null,
          lang : this.langsSelect[0].value,
          uuid : null
        })
      }
      this.displayDialog = true
      if ( this.usersSelect.length === 0 ) {
        this.usersSelect = (await this.GET_BUSINESS_USERS_SELECT()).users || []
      }


    },
    async openEdit($event) {
      this.newCertificate = false
      let result = await this.GET_CERTIFICATE_DATA_EDIT($event.uuid)
      if ( !result ) {
        return
      }
      this.entityData = result.certificate_data
      this.entityData.translations = []
      this.translations = result.translations
      if ( this.translations.length > 0 ) {
        this.translations.forEach(item => {
          this.entityData.translations.push({
            course_name : item.course_name,
            user_name : item.user_name,
            lang : item.lang,
            uuid : item.uuid
          })
        })
        this.entityData = this.COPY(this.entityData)
      }
      this.displayDialog = true
      this.selectedCertificate = this.COPY($event)
    },
    async initPage() {
      let result = await this.GET_COURSE_ISSUED_CERTIFICATES(this.uuid)
      if ( !result || result === true ) {
        return
      }
      result.reverse()
      this.items = result
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>