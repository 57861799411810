<template>
  <v-sheet
      v-if="element.type === 'text' "
      :color="element.config && element.config.has_background ? GET_AVALON_COLOR(element.config.background_color) : 'transparent'"
      :class="[
                { 'align-center' :  element.config.valign === 'center'  },
                { 'align-baseline' : !element.config.valign || element.config.valign === 'top'  },
                { 'align-end' :  element.config.valign === 'bottom'  }
            ]"
      :style="elementStyle"
      class="fill-height d-flex "
  >
    <h5 v-if="!config.is_dynamic"
        @mouseover="mouseOverText(true)"
        @mouseleave="mouseOverText(false)"
        @mousemove="mouseOverText(true)"
        v-html="text"
        @blur="changeText"
        :style="textStyle"
        contenteditable="plaintext-only"
        ref="text"
        class="editable"
    >
    </h5>
    <h4 v-else  :style="textStyle" style="width: 100%" >
      {{ dynamicTextName }}
    </h4>

  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "elementButtonEditor",
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    updateTrigger : {
      type : Number,
      default : 0
    },
    isDragging : {
      type : Boolean,
      default : false,
    },
    isSelected : {
      type : Boolean,
      default : false,
    }
  },
  data() {
    return {
      text : '',
      textarea : null,
      element : { config : {}},
      bufferText : '',
    }
  },
  computed : {
    textStyle(){
      let style = '';

      let { strong , color, italic , underline , font_size , align} = this.element.config
      if ( !font_size ) {
        font_size = 14
      }
      if ( strong ) {
        style += `font-weight : 600; `
      } else {
        style += `font-weight : 400; `
      }
      if ( underline ) {
        style += `text-decoration : underline; `
      }
      if ( italic ) {
        style += `font-style: italic; `
      }
      if ( font_size ) {
        style += `font-size : ${font_size}px;`
      }
      if ( color ) {
        style += `color : ${color};`
      }
      if ( align ) {
        style += `text-align : ${align};`
      }

      return style
    },
    elementStyle() {
      let style = ''

      if (this.element.config.outlined) {
        style += `border : ${this.element.config.outline_width || 1}px solid ${this.GET_AVALON_COLOR(this.element.config.background_color)} !important;`
      }
      if (this.element.config.rounded) {
        style += `border-radius : ${this.element.config.rounded}px;`
      }

      return style
    },
    config() {
      return this.element.config || {}
    },
    dynamicTextName() {
      if ( !this.config.content_type ) {
        return ''
      }
      let text = this.$t('DynamicText')
      let type = this.dynamicTextTypeSelect.find(el => el.value === this.config.content_type )
      if ( type ) {
        text = type.text
      }
      return `{${text}}`
    },
    dynamicTextTypeSelect() {
      return [
        { text : this.$t('SchoolName') , value : 'business_name' },
        { text : this.$t('StudentName') , value : 'student_name' },
        { text : this.$t('Date') , value : 'date' },
        { text : this.$t('CourseName') , value : 'course_name' },
        { text : this.$t('Result') , value : 'result' },
        { text : this.$t('CertificateNumber') , value : 'certificate_number' },
      ]
    },
  },
  watch : {
    value : {
      handler() {
        if ( !this.EQ(this.value, this.element) ) {
          this.element = this.COPY(this.value)
          this.text = this.element.text
        }
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('avalonPage', ['EDIT_ELEMENT']),

    mouseOverText(value) {
      if ( this.isDragging || !this.isSelected ) {
        return
      }
      this.$emit('mouseOverText' , value)
    },
    changeText() {
      let textElement = this.$refs.text
      let text = textElement.innerHTML
      this.element.text = text
      this.$emit('input' , this.COPY(this.element) )
    },
  },
  mounted(){
    if ( this.value ) {
      this.element = this.COPY(this.value)
      this.text = this.element.text
      this.$emit('input',this.element)
    }
  }
}
</script>

<style scoped>
.editable {
  outline: none !important;
  white-space : pre-line !important;
  width: 100%;
}
</style>